.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    margin: 0;
    padding: 0;
    background-color: $white;

    &__text {
        position: relative;
        z-index: 130;
    }

    &__overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 120;
        background-color: $yellow;
        transform: translateY(100%);
        animation: loaderIn cubic-bezier(0.16, 1, 0.3, 1) 1 0.8s forwards;
    }
}

@keyframes loaderIn {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
}