.eye {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 8rem;
    padding-bottom: 6rem;
    letter-spacing: 1rem;
    position: relative;
    border-bottom: 0.4rem $black solid;

    @include tablet {
        padding-bottom: 1rem;
        // max-width: none;
        // width: 100vw;
    }

    &>svg {
        width: 80%;
        margin: 6rem 0;
        overflow: visible;
        position: relative;
        top: 3rem;

        @include tablet {
            width: 100%;
            top: 1rem;
        }

        @include mobile {
            margin: 4rem 0;
        }
    }

    &__uppertext {

        &__text {
            font-size: 4.8rem;
            font-weight: 900;
        }
    }

    &__middletext {

        &__text {
            font-size: 3.1rem;
            font-weight: 400;
        }
    }

    &__lowertext {

        &__text {
            font-size: 4.8rem;
            font-weight: 900;
        }
    }

    &__hitbox {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}