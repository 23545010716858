.advert {
    display: flex;
    flex-direction: column;
    height: 32rem;
    border-bottom: 0.4rem $black solid;
    position: relative;

    @include mobile {
        height: 22rem;
    }

    &__row {
        width: 100%;
        position: relative;

        &--left {
            display: flex;
            flex-direction: column;
            padding: 2.4rem 0.8rem;
        }

        &--right {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    }

    &__title {
        max-width: 46rem;
        margin-bottom: 1.6rem;

        @include mobile {
            width: 64%;
        }
    }

    &__subtitle {
        max-width: 46rem;

        @include tablet {
            width: 64%;
        }

        @include mobile {
            width: 50%;
        }
    }

    &__image {
        height: 20rem;
        object-fit: contain;
        user-select: none;
        pointer-events: none;

        @include mobile {
            height: 14rem;
        }
    }
}