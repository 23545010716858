.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    @include tablet {
        padding: 0;
    }

    &--open {
        .header__wrapper {
            justify-content: flex-start;
            flex-direction: column;
            height: calc(100vh - 2.4rem);
        }

        .header__mobile {
            display: flex;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        position: relative;
        z-index: 110;
        overflow: hidden;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        height: 8rem;
        padding: 2.8rem 2.4rem 0 2.4rem;
        background-color: $black;
        transition: 0.4s cubic-bezier(0.16, 1, 0.3, 1);

        @include tablet {
            padding: 2.8rem 4.8rem 0 4.8rem;
        }

        @include mobile {
            padding: 2.8rem 2rem 0 2rem;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }

    &__mobile {
        display: none;
        flex-direction: column;
        width: 100%;

        &>.advert {
            margin-top: 3.2rem;
            border-color: $white;
        }

        &>.advert>.advert__row>.advert__image {
            height: 12rem;
        }

        &>.advert>.advert__row>.advert__title, &>.advert>.advert__row>.advert__subtitle {
            color: $white;
        }

        &__nav {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &__el {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 5.6rem;
                border-bottom: 0.4rem $white solid;

                &>a {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 0 0.8rem;
                    position: relative;
                    z-index: 10;
                    overflow: hidden;
                    transition: color 0.4s cubic-bezier(0.16, 1, 0.3, 1);

                    &::before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: $yellow;
                        position: absolute;
                        z-index: -10;
                        top: 0;
                        left: 0;
                        transform: translateX(-101%);
                        transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
                    }

                    &:hover {
                        color: $black;

                        &::before {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }

    &__logotype {
        font-size: 2.4rem;
        color: $white;
        line-height: 2.4rem;

        @include tablet {
            font-size: 2.4rem;
        }
    }

    &__nav {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;

        @include tablet {
            display: none;
        }

        &__el {
            margin-left: 4.8rem;
            cursor: pointer;
            user-select: none;
            color: $white;
            font-size: 1.8rem;
            font-weight: 600;

            @include tablet {
                font-size: 1rem;
                margin-left: 3.2rem;
            }
        }
    }

    &__toggle {
        display: none;
        cursor: pointer;
        user-select: none;

        @include tablet {
            display: block;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 2.8rem;
        }
    }

    &__coupon {
        height: 0.8rem;
        position: relative;
    }
}