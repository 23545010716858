.project-card {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 32rem;
    padding: 2.4rem 0.8rem;
    border-bottom: 0.4rem $black solid;
    position: relative;
    background-color: #fff;

    @include tablet {
        height: 30rem;
    }

    @include mobile {
        height: 23rem;
    }

    &__row {
        width: 100%;

        @include tablet {
            width: 100%;
            height: 100%;
        }

        &--left {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 2.4rem 0.8rem;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            user-select: none;
            pointer-events: none;
        }

        &--right {

            @include tablet {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__head {
        width: 50%;

        @include tablet {
            width: 100%;
        }
    }

    &__foot {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height: 50%;
        padding: 2.4rem 0.8rem;
        position: absolute;
        bottom: 0;
        left: 0;
        user-select: none;

        @include mobile {
            margin-top: 2.4rem;
        }
    }

    &__title {
        margin-bottom: 1.6rem;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 0.4rem;
            // background-color: $yellow;
        }

        @include mobile {
            margin-bottom: 0.8rem;
        }
    }

    &__date {

        @include mobile {
            font-size: 1.8rem;
        }
    }

    &__role {
        &--0 {
            margin-top: auto;
        }
    }

    &__roles {
        width: 50%;

        @include tablet {
            width: 100%;
        } 
    }

    &__arrow {
        font-size: 4rem;
        pointer-events: visible;
        position: absolute;
        right: 0;
    }

    &__letters {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}