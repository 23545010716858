// RESET
h1, h2, h3, h4, h5, p, span {
    margin: 0;
    padding: 0;
    // font-size: 1rem;
}

ol, ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}