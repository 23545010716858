.footer {
    width: 100%;
    margin-top: 4.8rem;
    position: absolute;
    bottom: 0;

    @include tablet {
        width: 100vw;
        margin-top: 3.2rem;
        padding: 0;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        height: 24px;
    }

    &__svg {
        position: absolute;
        top: 0;
    }

    &__container {
        width: 100%;
        height: 1.6rem;
        background-color: $black;
        position: absolute;
        bottom: 0;
    }
}