@font-face {
    font-family: "Cassannet";
    src: url("../../assets/fonts/Cassannet_Plus_Ultra.otf") format('opentype');
    font-weight: 900;
}
@font-face {
    font-family: "Cassannet";
    src: url("../../assets/fonts/Cassannet_Plus_Bold.otf") format('opentype');
    font-weight: 600;
}
@font-face {
    font-family: "Cassannet";
    src: url("../../assets/fonts/Cassannet_Plus_Regular.otf") format('opentype');
    font-weight: 400;
}
@font-face {
    font-family: "Cassannet";
    src: url("../../assets/fonts/Cassannet_Plus_Light.otf") format('opentype');
    font-weight: 200;
}
@font-face {
    font-family: "Cassannet";
    src: url("../../assets/fonts/Cassannet_Plus_Thin.otf") format('opentype');
    font-weight: 100;
}

.font {

    &__title {
        font-family: "Cassannet", sans-serif;
        font-weight: 900;
        font-size: 4.8rem;
        line-height: 5.2rem;
        text-transform: uppercase;

        @include mobile {
            font-size: 2.4rem;
            line-height: 2.4rem;
        }
    }

    &__subtitle {
        font-family: "Cassannet", sans-serif;
        font-weight: 400;
        font-size: 3.1rem;
        line-height: 3rem;
        text-transform: uppercase;

        @include mobile {
            font-size: 1.8rem;
            line-height: 1.8rem;
        }
    }

    &__text {
        font-family: 'Cassannet', sans-serif;
        font-weight: 400;
        font-size: 2rem;
        line-height: 2.4rem;
        text-transform: uppercase;

        @include mobile {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    &__yellow {
        color: $yellow;
    }

    &__white {
        color: $white;
    }

    &__black {
        color: $black;
    }

    &__wdif {
        color: white;
        mix-blend-mode: difference;
    }

    &__special {
        color: $yellow;
        -webkit-text-stroke: 0.2rem $black;
        
        @include mobile {
            -webkit-text-stroke: 0.15rem $black;
        }
    }
}