.contact {
    margin-top: 6rem;
    min-height: calc(100vh - 6rem);
    padding-bottom: 6rem;

    &__content {
        margin-top: 6rem;
    }

    &__media {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        padding: 3.2rem 0 2.8rem 0;
        border-bottom: 0.4rem $black solid;

        &__img {
            width: 5.6rem;

            @include mobile {
                width: 4.2rem;
            }
        }
    }

    &__section {
        margin-top: 3.2rem;
        padding-bottom: 3.2rem;
        border-bottom: 0.4rem $black solid;

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        &__title {
            margin-bottom: 2.4rem;
        }

        &__text {
            margin-bottom: 2.4rem;
            max-width: 30ch;
        }

        &__list {
            margin-bottom: 3.2rem;
        }
    }
}