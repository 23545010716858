.about {
    margin-top: 6rem;
    padding-bottom: 6rem;

    &__content {
        margin-top: 6rem;
    }

    &__section {
        margin-top: 3.2rem;
        padding-bottom: 3.2rem;
        border-bottom: 0.4rem $black solid;

        &:last-child {
            border: none;
            padding-bottom: 0;
        }

        &__title {
            margin-bottom: 2.4rem;
        }

        &__text {
            margin-bottom: 2.4rem;
            max-width: 30ch;
        }

        &__list {
            margin-bottom: 3.2rem;
        }
    }
}